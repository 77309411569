.container {
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    min-height: 100vh; /* Make it full viewport height */
    padding: 2rem;
    gap: 2rem;
    box-sizing: border-box;
  }
  
  .form {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    flex: 1;
    margin-right: 2rem;
    max-width: 500px; /* This can be adjusted as per your preference */
  }

.imageReminderContainer  {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.appointmentImage {
    max-width: 800px;
    height: auto;
    margin-bottom: 2rem; /* Separation between image and reminder */
}
  
  /* Input and select styles */
  .form input,
  .form select {
    width: 100%;
    padding: 0.8rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Button styles */
  .form button {
    background-color: #FCE6E9;
    color: black;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .form button:hover {
    background: rgb(229,233,236);
  }

  .reminder {
    background-color: #f7f7f7; /* Light gray background for distinction */
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    flex-basis: 200px;
    max-width: 400px; /* Adjust this based on your design preference */
  }

  fieldset {
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    padding: 0.5rem;
 }

  legend {
        font-weight: bold;
 }

 label {
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    gap: 0.5rem; /* Spacing between radio button and text */
    margin-bottom: 0.5rem;
    cursor: pointer;
 }

 input[type="radio"] {
    margin-top: 1rem;
    width: 0.8rem;
    height: 0.8rem;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 856px) {
    /* Adjust styles for medium-sized screens */
    .form {
      padding: 1.5rem;
    }
    .imageReminderContainer  {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .appointmentImage {
        max-width: 250px;
        height: auto;
        margin-bottom: 2rem; /* Separation between image and reminder */
    }
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
    .imageReminderContainer  {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .appointmentImage {
        max-width: 250px;
        height: auto;
        margin-bottom: 2rem; /* Separation between image and reminder */
    }
  }
  
  @media screen and (max-width: 640px) {

    .container {
        align-items: center;
        justify-content: center;
      }
    /* Adjust styles for small screens */
    .form {
      padding: 1rem;
      margin: 1rem;
    }

    .form input,
    .form select {
      padding: 0.5rem 0.8rem;
    }
    .imageReminderContainer  {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .appointmentImage {
        max-width: 250px;
        height: auto;
        margin-bottom: 2rem; /* Separation between image and reminder */
    }
  }
  