.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;  /* full width of viewport */
    height: 100vh; /* full height of viewport */
}
.wrapper {
    flex: 1;
    padding: 50px;
    height: 100%; /* This determines how much of the image is shown. Adjust as needed. */
    overflow: hidden;
}
.wrapper img {
    width: 100%;
    height: 80vh; /* This ensures the image retains its original aspect ratio */
    display: block;
    object-fit: contain; 
    object-position: center; /* Adjust if necessary to center or align the part of the image you want to show */
    position: relative;
    z-index: 2; /* Makes sure image is above gradient overlay */
    mask-image: radial-gradient(ellipse at center, white 60%, transparent 100%);
}
.wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    mask-image: radial-gradient(ellipse at center, rgba(211, 211, 211, 0.7) 60%, transparent 100%); /* Gradient from light grey to transparent, vertically */
    pointer-events: none;
    z-index: 1; /* Makes sure gradient overlay is above the image */
}
/* Left side styles (your text) */
.h_sides {
    flex: 1;  /* take up half the width */
    padding: 50px; /* spacing from edges, adjust as needed */
}
.text1 {
    font-size: 3em; /* make the header prominent */
    margin-bottom: 20px; /* spacing between header and subtext */
}
.text2 span:first-child {
    font-size: 1.8em; /* subheader size */
    display: block; /* make it occupy its own line */
    margin-bottom: 10px; /* spacing between subheader and description */
}
.cart2 {
    /* Remove absolute positioning, and add any desired margins for spacing */
    margin-top: 20px; /* adjust as needed */
    display: flex;
    gap: 1rem;
    align-items: center;
}
.cart2>svg{
        width: 30px;
        height: 30px;
        background: white;
        padding: 10px;
        border-radius: 50%;
        border: 6px solid var(--black);
 }
.signup{
        display:flex;
        gap: 1rem;
        align-items: center;
        background: white;
        padding: 10px;
        font-size: .8rem;
        border-radius: 15px;
        box-shadow: var(--shadow1);
}
.signup>:first-child{
        display: block;
        width: 6rem;
}
.signup>:nth-child(2){
        border-radius: 50%;
        border: 1px solid skyblue;
        display: flex;
        width: 20px;
        height: 20px;
        padding: 5px;
        align-items: center;
        justify-content: center;
}

/* Right side (currently commented out) */
/* If you decide to use this in the future, use a similar flex value like the left side. */
/* .container{
    padding: 0rem 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}
.wrapper{
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}
.wrapper>img{
    width: 30rem;
    position: absolute;
    bottom: 0;
    border-radius: 30%;
}
.blueCircle{
    width: 30rem;
    height: 30rem;
    border-radius: 50%;
    z-index: -99;
    position: absolute;
    bottom: 0;
    background: rgb(229,233,236)
}
.cart2{
    position: absolute;
    bottom: 25%;
    right: 5%;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.cart2>svg{
    width: 30px;
    height: 30px;
    background: white;
    padding: 10px;
    border-radius: 50%;
    border: 6px solid var(--black);
}
.signup{
    display:flex;
    gap: 1rem;
    align-items: center;
    background: white;
    padding: 10px;
    font-size: .8rem;
    border-radius: 15px;
    box-shadow: var(--shadow1);
}
.signup>:first-child{
    display: block;
    width: 6rem;
}
.signup>:nth-child(2){
    border-radius: 50%;
    border: 1px solid skyblue;
    display: flex;
    width: 20px;
    height: 20px;
    padding: 5px;
    align-items: center;
    justify-content: center;
}

.h_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.text1{
    text-transform: uppercase;
    font-size: 1.5rem;
    width: min-content;
    display: block;
    font-weight: 600;
}   
.text2{
    display: flex;
    flex-direction: column;
    width: min-content;
    color: black;
}
.text2>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}
.text2>:nth-child(2){
    display: block;
}
.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;
}
.traffic>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}
.customers{
    display: flex;
    flex-direction: column;
    text-align: right;
}
.customers>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}

@media screen and (max-width: 856px) {
 
    .text1, .text2>span:nth-of-type(2){
        font-size: .9rem;
        text-align: justify;
    }

    .text2>span:nth-of-type(1),
    .traffic>span:nth-child(1),
    .customers>span:nth-child(1){
        font-size: 1.5rem;
        text-align: left;
    }

    .blueCircle{
        position: absolute;
        width: 70%;
        height: 100%;
    }
    .wrapper>img{
        width: 70%;
        height: 100%;
    }
    .container{
        grid-template-areas: 
        'left center center'
        'right right right';
    }
    .container>:first-child{
        grid-area: left;
        grid-template-rows: none;
        gap: 8rem;
    }
    .container>:nth-child(2){
        grid-area: center;
    }
    .container>:nth-child(3){
        grid-area: right;
        display: flex;
        justify-content: space-around;
        margin: 2rem 0rem;
    }
    

}
@media screen and (max-width: 640px) {
    .blueCircle{
        top: 0;
        width: 16rem;
        height: 72%;
    }
    .wrapper>img{
        top: 3rem;
        width: 12rem;
        height: 15rem;
    }
    .cart2{
        transform: scale(.7);
        right: -2rem;
        bottom: 0;
    }
}
 */
