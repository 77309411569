:root{
  --black: #343434;
  /* --bg: #F8E367; */
  --bg: #FCE6E9;
  --shadow1: 0px 13px 48px -1px rgba(0, 0, 0, 0.25);
  --shadow2:  0px 13px 46px rgba(0, 0, 0, 0.13);
  /* --pink: #FE956F; */
  --pink: #ffffff;
  --off-white: #FAFAF5; /* Here's your off-white color */
}
.App{
  display: flex;
  flex-direction: column;
}
::-webkit-scrollbar{
  height: 12px;
  width: 12px;
  }
  
  ::-webkit-scrollbar-track{
  background: #ffffff;
  border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-thumb{
  /* background: skyblue; */
  background:  #EFEFEF;
  border-radius: 1ex;
  }

  a{
    text-decoration: none;
    color: inherit
  }