.advertisement {
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 10%;
    background-image: radial-gradient(white 50%, #FCE6E9);
    
}

.textContent {
    flex: 1; 
    font-size: 1.2rem;
}

h2 {
    font-size: 2rem;
    margin-bottom: 15px;
}

p {
    margin-bottom: 20px;
}

.discoverButton {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 600;
    background-color: #FCE6E9; /* Adjust color */
    color: black; /* Adjust text color */
    border: none;
    cursor: pointer;
    border-radius: 5px; /* Slight rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition */
}

.discoverButton:hover {
    background-color: #555; /* Slightly lighter shade on hover */
}

.adImage {
    width: auto;
    height: auto;
    border-radius: 50%; 
    object-fit: cover; 
}
